<template>
  <b-button
    v-b-modal="`modal-importar-${modulo}`"
    variant="secondary"
    class="btn-sm btn-sm-block"
    :title="`Importar ${modulo}`"
  >
    <feather-icon
      icon="UploadCloudIcon"
    />
  </b-button>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { formatos } from '@/mixins/forms'

export default {
  components: {
    BButton,
  },
  mixins: [formatos],
  props: {
    modulo: {
      type: String,
      required: true,
    },
  },
  computed: {
    moduloTitle() {
      return this.getTitle()
    },
  },
  methods: {
    getTitle() {
      const title = this.modulo
      return `No tienes permisos para importar ${this.primeraMayuscula(title)}`
    },
  },
}
</script>
